<template>
  <el-main>
    <div class="btn">
      <router-link to="/gateway/goods/addGoods"><el-button type="primary" class="el-icon-plus" size="small">添加商品</el-button></router-link>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品名称：">
        <el-input size="small" v-model="content.goods_name" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="商品分类：">
        <el-cascader size="small" clearable v-model="content.classify_id" :options="goodsClass" @change="typeChange"></el-cascader>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="searchGoods" type="primary">搜索</el-button>
        <el-button size="small" class="boderBlue" @click="exportEx">导出</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="content.status" type="card" @tab-click="handleClick(content.status)">
      <el-tab-pane :name="item.status" v-for="(item, index) in goodsStatus" :key="index">
        <span slot="label">{{ item.title }}（{{ item.count }}）</span>
      </el-tab-pane>
    </el-tabs>
    <GoodsList ref="goodsList" :goodsClass="goodsClass" :content="content"></GoodsList>
  </el-main>
</template>
<script>
import GoodsList from '../components/goodsList';
import config from '@/util/config';
import _ from 'lodash';
const industryName = '/Gateway';//行业版本接口名称（用于更换重复接口）
export default {
  components: {
    GoodsList,
  },
  data() {
    return {
      goodsStatus: [],
      content: {
        status: 0,
        goods_name: '',
        classify_id: '',
      },
    };
  },
  computed: {
    goodsClass() {
      let goodsTypeList = this.$store.getters.goodsTypeList;
      goodsTypeList.unshift({
        value: 0,
        label: '全部',
      });
      return goodsTypeList;
    },
  },
  created() {
     this.$store.dispatch('goods/getTypeList', { industryName });
    this.getGoodsStatus();
  },
  methods: {
    // 导出数据成excel
    exportEx() {
      this.$axios.post(industryName + this.$api.goods.export).then(res => {
        if (res.code == 0) {
          let path = config.baseurl + '/' + res.result;
          let a = document.createElement('a');
          a.href = path;
          a.id = 'download';
          document.body.appendChild(a);
          a.click();
          let aDom = document.getElementById('download');
          document.body.removeChild(aDom);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick() {
      this.$refs.goodsList.page = 1;
      this.$refs.goodsList.rows = 10;
      this.$refs.goodsList.allchecked = !1;
      this.$refs.goodsList.getGoodsList();
    },
    typeChange(val) {
      val.length - 1 ? (this.content.classify_id = val[1]) : (this.content.classify_id = val[0]);
    },
    getGoodsStatus() {
      this.$axios
        .post(industryName + this.$api.goods.goodsStatus, {
          goods_name: this.content.goods_name,
          classify_id: this.content.classify_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.goodsStatus = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    searchGoods() {
      this.getGoodsStatus();
      this.$refs.goodsList.getGoodsList();
    },
    searchClear() {
      this.content.goods_name = '';
      this.content.classify_id = '';
      this.getGoodsStatus();
      this.$refs.goodsList.getGoodsList();
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  display: flex;
  flex-direction: column;
  .btn {
    margin-bottom: 10px;
    .el-button {
      margin-right: 10px;
    }
  }
}
</style>
